import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { SectionHero, Section, SectionDescription, SectionPageTitle, SectionImageBkg } from "../components/Section"
import SearchBar from "../components/SearchBar"
import ColorPicker from "../components/ColorPicker"


const SectionHeros = styled(SectionHero)`
	position:relative;
    height: 100%;
`
const SectionImageBkgs = styled(SectionImageBkg)`
    display:none;
    > .gatsby-image-wrapper{
        /* @media (min-width:1200px){
            height:auto;
            position: absolute;
            bottom: 0;
        }     */
    }
    @media (min-width:768px){
        display:block;
    }
`
const SectionImageBkgMobile = styled(SectionImageBkg)`
    display:block;
    position:relative;
    .gatsby-image-wrapper {
        position:relative !important;
    }
    @media (min-width:768px){
        display:none;
    }
`
const HeroBanner = styled.div`	
	position: relative;   
`

const HeroCaption = styled.div`	
    position:relative;
    z-index:1;
    padding:40px 0;
    @media (min-width:992px){
        padding:60px 0;
    }
    @media (min-width:1200px){
        padding:60px 0 140px;
    }
    @media (min-width:1440px){
        padding:60px 0 250px;
    }
    @media (min-width:1600px){
        padding:120px 0 300px;
    }
`
const HeroSearch = styled.div`
    @media (min-width:992px){
        min-height: 78px;
        margin-top:-78px;
        position: sticky;
        bottom:0;
        left:0;
        z-index: 1;    
    }
    @media (min-width:1600px){
        min-height:100px;
        margin-top:-100px;
    }
    .search-bar{
        @media (min-width:992px){
            border-radius: 15px 15px 0 0;
        }
        @media(min-width:1200px){
            border-radius: 20px 20px 0 0;
        }
    }
    .container{
        @media (max-width:991.98px){
            padding:0;
        }
    }
`

const HeroCaptionGrid = styled.div`
	display:flex;
    flex-wrap:wrap;
    margin:0 -15px;
`
const HeroCaptionLeft = styled.div`
    position:relative;
    width:100%;
    padding:0 15px;
    @media (min-width:768px){
        max-width:58.333333%;
        flex:0 0 58.333333%;
    }
`
const SectionColor = styled(Section)`
   .color-palette{
       padding:80px 0 0;
        @media (min-width:992px){
            padding:120px 0 0;
        }
        @media (min-width:1600px){
            padding:160px 0 0;
        }
   }
`

const ColorOptions = ({ location, data }) => (
  <Layout location={location}>
    <Seo
      title={data.allContentfulPageData.edges[0].node.metaTitle}
      description={data.allContentfulPageData.edges[0].node.metaDescription.metaDescription}
    />
    <SectionHeros className="section-hero" bgColor="#f3f5f7">
      <HeroBanner>
        <HeroCaption>
          <div className="container">
            <HeroCaptionGrid>
              <HeroCaptionLeft>
                <SectionPageTitle className="h2" mb="0" fontWeight="900">COLOR OPTIONS</SectionPageTitle>
                <SectionDescription fontStyle="italic">
                  <p>Tubular Building Systems offers 15 color options that cover a wide spectrum of choices for both his and hers. You will find our color spectrum includes both traditional tones, and more modern options. You choose the colors not just of your sides and ends, but also the roof, trim, and even wainscot options. Wainscot comes at no extra cost for horizontal paneling, but there is an extra fee for vertical wainscot. </p>
                  <p>With our wide range of color options and <u><Link to='/roofstyle'>roof styles</Link></u>  you can match an existing structure such as your home or go for something bold and unique.</p>
                </SectionDescription>
              </HeroCaptionLeft>
            </HeroCaptionGrid>
          </div>
        </HeroCaption>
        <SectionImageBkgs><StaticImage src="../images/color-hero.jpg" alt="color-hero" /></SectionImageBkgs>
        <SectionImageBkgMobile><StaticImage src="../images/color-hero-mobile.jpg" alt="color-hero-mobile" /></SectionImageBkgMobile>
      </HeroBanner>
      <HeroSearch>
        <div className="container" ><SearchBar /></div>
      </HeroSearch>
    </SectionHeros>
    <SectionColor pt="0" pb="0">
      <ColorPicker />
    </SectionColor>
  </Layout>
)
export default ColorOptions


export const pageQuery = graphql`
  query ColorOptionsPageQuery{
    allContentfulPageData(filter: {page: {eq: "Color Options"}}) {
      edges {
        node {
          metaTitle
          metaDescription {
            metaDescription
          }
        }
      }
    }
  }
`