import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import ColorPattern from "../../images/color-pattern.png"
import "./color-picker.scss"

const ColorPalette = styled.div`
    position:relative;
    background-color: ${props=>props.bgColor};	
    &:after{
        display: block;
        content: '';
        position: absolute;
        top: 0;
        background:${props => props.bgAfter};
        background-repeat: repeat;
        width: 100%;
        height: 100%;
    }
    > small{
        position: absolute;
        bottom: -35px;
        z-index: 1;
        font-size: 14px;
        font-weight: 400;
        width: 100%;
        text-align: center;
    }
`
const CustomTooltip = styled.div`
    background: #1383c1;
    position: relative;
    bottom: 0;
    z-index: 1;
    width: 100%;
    text-align: center;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    color: #fff;
    padding: 10px 15px;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    
    &:after, &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    
    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #1383c1;
        border-width: 15px;
        margin-left: -15px;
    }
    &:before {
        border-color: rgba(0, 0, 0, 0);
        border-bottom-color: #1383c1;
        border-width: 16px;
        margin-left: -16px;
    }
`
const ColorCircle = styled.div`
    position: relative;
    z-index: 1;
    width:100%;
    margin: 0 auto;
    top:-40px;
    @media(min-width:1200px){
        top:-80px;
    }

`
const ColorPicker = () => {
    const data = useStaticQuery(
        graphql`
            query {
                allContentfulColor {
                    edges {
                      node {
                        name
                        hexValue
                      }
                    }
                }
            }
        `
    );
    const [ colorName, setColorName ] = useState("Black")
    const [ colorValue, setColorValue ] = useState("#0c0e0d")

    const setColor = (name, value) => {
        setColorName(name);
        setColorValue(value);
    }
    return (
        <ColorPalette bgColor={colorValue}  bgAfter={`url(${ColorPattern})`} className="color-palette">
            <div className="container">
                <ColorCircle className="color-circle">
                    {/* <StaticImage src="../../images/color-large-circle.png" alt="color-large-circle" /> */}
                    <div class="donut-chart-block block"> 
                        <div class="donut-chart">
                            {
                                data.allContentfulColor.edges.map((item, i) => {
                                    if (colorName === item.node.name) {
                                        return(
                                            <div id={`part${i+1}`} class="portion-block active" onClick={()=> setColor(item.node.name, item.node.hexValue)}>
                                                <div class="circle" style={{ background: `${item.node.hexValue}`}}></div>
                                            </div>
                                        )
                                    } else {
                                        return(
                                            <div id={`part${i+1}`} class="portion-block" onClick={()=> setColor(item.node.name, item.node.hexValue)}>
                                                <div class="circle" style={{ background: `${item.node.hexValue}`}}></div>  
                                            </div>
                                        )
                                    }
                                })
                            }
                            { colorName === "Polar White" || colorName === "Ivory" ? (
                                <div class="color-name" style={{ background: `${colorValue}`, color: "#0c0e0d"}}>{colorName}</div>
                            ) : (
                                <div class="color-name" style={{ background: `${colorValue}`}}>{colorName}</div>
                            )}
                            
                        </div>
                    </div>
                    
                </ColorCircle>
            </div>
            <CustomTooltip>**The above colors are a digital approximation and are intended only as an approximation of actual colors**</CustomTooltip>
        </ColorPalette>
    )
}

export default ColorPicker
